.select {
  padding: 7px 25px 7px 15px;
  font-size: 20px;
  border: 2px solid #e3e6e4;
  border-bottom: 2px solid orange;
  border-radius: 8px;
  outline: 0;
}
.select:focus {
  background-color: #f0f0f0;
}
