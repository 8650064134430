.main{
    margin: 0.5rem 0;
}
.label{
    font-weight: bold;
    padding-top: 2.6rem;
}
.text{
    margin: 1rem 0 0.5rem 0 !important;
    font-weight: 400 !important;
}
