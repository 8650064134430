.price_box_hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
}

.price_box_show {
    visibility: visible;
    opacity: 1;
    transition: visibility 1s 0s, opacity 1s linear;
}

.fadeOut {
    visibility: hidden !important;
    opacity: 0 !important;
    transition: visibility 1s, opacity 0.5s linear;
}
.fadeIn {
    visibility: visible;
    opacity: 1;
    transition: visibility 1s, opacity 0.5s linear;
}
