.select {
  /* width: 100%; */
  padding: 8px 9px;
  font-size: 17px;
  margin-top: 18px;
  display: inline-block;
  border: 2px solid #d8d8d8;
  border-bottom: 2.9px solid orange;
  border-radius: 8px;
  outline: 0;
  height: 3.6rem;
  cursor: pointer;
}
.select:focus {
  background-color: #f0f0f0;
  border-color: black;
}
