@keyframes closing_animation {
  from {
    margin-top: 0rem;
  }

  to {
    margin-top: -2px;
  }
}

.newCardClose {
  animation: closing_animation 1s;
  margin-top: -2px;
}
