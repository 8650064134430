@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/open-sans/woff2/OpenSans-Regular.woff2') format('woff2'),
    url('assets/fonts/open-sans/woff/OpenSans-Regular.woff') format('woff'),
    url('assets/fonts/open-sans/ttf/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/open-sans/woff2/OpenSans-Bold.woff2') format('woff2'),
    url('assets/fonts/open-sans/woff/OpenSans-Bold.woff') format('woff'),
    url('assets/fonts/open-sans/ttf/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/open-sans/woff2/OpenSans-Light.woff2') format('woff2'),
    url('assets/fonts/open-sans/woff/OpenSans-Light.woff') format('woff'),
    url('assets/fonts/open-sans/ttf/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/open-sans/woff2/OpenSans-BoldItalic.woff2') format('woff2'),
    url('assets/fonts/open-sans/woff/OpenSans-BoldItalic.woff') format('woff'),
    url('assets/fonts/open-sans/ttf/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/open-sans/woff2/OpenSans-LightItalic.woff2') format('woff2'),
    url('assets/fonts/open-sans/woff/OpenSans-LightItalic.woff') format('woff'),
    url('assets/fonts/open-sans/ttf/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --primary-dark-color: #ff7300;
  --panel-back-color: #f8f8f8;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: all 0.4s;
}
.fade-enter-done {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: all 0.4s;
}
.fade-exit-done {
  opacity: 0;
}

.truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.panel {
  background-color: var(--panel-back-color);
}
.sliding-panel-container.active {
  z-index: 1500 !important;
}

#popper-container .ui.inverted.top.popup:before {
  background: #99bc82 !important;
}

#popper-container .ui.inverted.bottom.popup:before {
  background: #99bc82 !important;
}
#popper-container.x-error .ui.inverted.bottom.popup:before {
  background: #e84040 !important;
}
#popper-container.x-error .ui.inverted.top.popup:before {
  background: #e84040 !important;
}

.disable {
  pointer-events: none;
  opacity: 0.3;
}

.pointerNone {
  pointer-events: none;
}

.validationsContainer {
  display: flex;
}

.signupValidations {
  color: #7da166;
  font-size: 14px;
  margin-right: 8px;
  margin-top: 3px;
  min-width: 15px;
}

.mr-1rem {
  margin-right: 1rem;
}
.mt-01rem {
  margin-top: 0.1rem;
}

.--focused {
  border: 0.8px solid #dbdcdd !important;
  border-bottom: 1px solid #dbdcdd !important;
  box-shadow: 0px 0px 0px !important;
}

.css-1gzrj0n {
  border-bottom: 2px solid orange !important;
  resize: vertical;
  overflow: auto;
  /* min-height: 160px !important; */
  background-color: white;
  outline: none !important;
  border-right: 1px solid rgb(212 212 216) !important;
  border-left: 1px solid rgb(212 212 216) !important;
  border-top: 1px solid rgb(212 212 216) !important;
}

@media only screen and (max-width: 1280px) {
  .zopim {
    display: none !important;
  }
}

.line-break {
  white-space: pre-wrap;
}

.focused-payment {
  position: relative;
  z-index: 100;
  background: white;
  transition: position 1s;
  border-radius: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
