.Toastify__toast--default {
	color: white !important;
}
.Toastify__toast-container {
	width: 401px !important;
}

.error {
	background: #c60506 !important;
}

.success {
	background: #398839 !important;
	width: 100%;
	height: 100%;
}
