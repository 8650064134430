/* Manage vm */
.mobile_manage_btn {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.manage_mobile_pagination {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.manage_bottom_mobile_pagination {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
}

.flex_link {
    display: flex;
}

.loading_spinner {
    margin-top: .1rem;
    margin-left: 1rem;
    height: 1rem;
}

.no_server {
    margin: 5px 0;
}

.bottomMarginSty {
    margin-bottom: 1.5rem;
}

.rightMarginSty {
    margin-right: 1rem;
}

.topMarginSty {
    margin-top: 0.1rem;
}

.noserver_container {
    display: flex;
    flex-direction: column;
}

.noserver_inner_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Tag Card */
.tag_card_flex {
    display: flex;
}

.tag_card_heading_none {
    color: black;
    font-weight: 500;
    margin-right: 0.5rem;
    font-size: 15px;
}

.tag_card_icon_pencil {
    margin-top: -5px
}


/* Manage vm mobile */

.width {
    width: 100%;
}

.tag_heading_icon {
    display: flex;
    margin-top: 0.5rem;
}

.pencil_icon {
    margin-top: -5px;
    margin-right: 1rem;
}

.margin_top {
    margin-top: -3px;
}

.margin_left {
    margin-left: 0.5rem;
}

.deleted_server_sty {
    margin: 0 4px;
    text-decoration: underline;
    cursor: pointer;
}

.last_row {
    display: flex;
    align-items: center;
}

.manager_vm_backup {
    font-size: 12px;
    margin-right: 0.3rem;
}

.never_heading {
    font-size: 12px;
    margin-right: 0.5rem;
}