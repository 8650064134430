#dropdown-basic{
    border-radius: 50%;
    padding: 10px 5px ;
    display: flex;
    align-items: center;
    border: 1px solid #e9e9e9;
    color: #FF7300;
    cursor: pointer;
    background-color: transparent;
    box-shadow:none ;
}
dropdown-basic:hover{
    border-radius: 50%;
    border: 1px solid #e9e9e9; 
    color: #FF7300;
    cursor: pointer;
    background-color: transparent; 
}
.btn-primary:active, .show>.btn-primary.dropdown-toggle {
    color: #FF7300;
    background-color: transparent;
    border-color: #e9e9e9;
}
.menu{    
    box-shadow: 0 0 5px 1px #d1d1d1;
}
.menu .icon span{    
    color:#FF7300;
    margin-right: 8px;    
    width: 1.3em;
    font-size:17px;
    
}
.menu .icon p{    
    font-weight: 490 !important;
}






