.payment {
  display: flex;
  flex-wrap: wrap;
}
.card_form {
  border-left: 1px solid orange;
  padding-left: 2rem;
}

.payment_box {
  background-color: #cfcfcf;
  display: flex;
  flex-wrap: nowrap;
  padding: 0.5rem 1.3rem 0.2rem 0.8rem;
  border-radius: 10px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  margin: 1rem 1rem 1rem 0rem;
  align-items: center;
}

.payment_box:hover {
  border-bottom: 2px solid orange;
}

.content {
  margin: 4rem 0 5rem;
}

.link {
  text-decoration: underline;
  cursor: pointer;
  color: #3567ce;
}

.link_disabled {
  text-decoration: underline;
  cursor: pointer;
  color: #3567ce;
  pointer-events: none;
}

@keyframes closing_animation {
  from {
    margin: 200px 0;
  }

  to {
    margin: 0;
  }
}

.paypalDesc {
  font-size: 1.2rem !important;
  animation: closing_animation 1s;
  border-left: 1px solid orange;
  padding-left: 2rem;
}

.default_credit {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-left: 1px solid orange;
  padding-left: 2rem;
  padding-bottom: 2rem;
}

.exp {
  font-size: 1.3rem !important;
  margin: 0 !important;
  margin-right: 10px !important;
}

.heading {
  margin: 1rem 10rem 1rem 0rem;
}

@keyframes credit_animation {
  from {
    margin: 100px 0;
  }

  to {
    margin: 0.5rem 0;
  }
}

.main {
  margin: 0.5rem 0;
  animation: credit_animation 1s !important;
}

.label {
  padding-top: 2.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.roleslabel {
  padding: 0.7rem 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.issuer_phone {
  font-size: 16px;
  max-width: 23.5rem;
  margin-top: 1rem;
}

.text {
  font-size: 18px;
  margin: 1rem 0 0.5rem 0 !important;
}

@media only screen and (max-width: 768px) {
  .heading {
    margin: 1rem 4rem 1rem 0rem !important;
  }
}

@media only screen and (max-width: 630px) {
  .exp {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 550px) {
  .content {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 450px) {
  .issuer_phone {
    max-width: 100%;
  }
}
.margin {
  padding-top: 33px;
  font-size: 19px;
}

.input {
  padding: 5px 35px 0 15px;
  font-size: 18px;
  margin: 7px 0;
  border: 2px solid #d8d8d8;
  border-bottom: 2.9px solid orange;
  border-radius: 2px;
  outline: 0;
  font-family: sans-serif;
  height: 3rem !important;
}

.inputWidth {
  padding: 5px 35px 0 15px;
  font-size: 18px;
  margin: 7px 0;
  display: inline-flex;
  border: 2px solid #d8d8d8;
  border-bottom: 2.9px solid orange;
  border-radius: 2px;
  outline: 0;
  font-family: sans-serif;
  height: 3rem !important;
  width: 90%;
}

.input:focus {
  background-color: #f0f0f0;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 18.5px;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 19px;
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 19px;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-size: 19px;
}
